import styles from '../../styles/NewsletterSignup.module.css'

export default function NewsletterSignup(props) {
    return (
        <div className={styles["main-container"]}>
            <div className={styles["background-static"]} />
            <div className={styles["signup-container"]}>
                <iframe width="540" height="805" src="https://b3313bfc.sibforms.com/serve/MUIFABLRIrbd09I61l-7pP5kjj6ObXyRwyC24K1vSDzDwk7DdebjTAhOINbQsAUkRoYjz3Z5qNtxVQS2Q99bauBGbcOd9XBSREBGduma5kY31fcBj2xl7XQ10LPK5_304KHz9BQr92HyGUL82PErMIwCS8rm63YcmdLgSC2j9TiqeG0IQnHy5uuRAWmrugr01OHsbPMsZTSh0KTf"  frameborder="0" allowFullScreen allowTransparency="true" style={{ "display": "block", "backgroundColor": "rgba(0,0,0,0)", "marginLeft": "auto", "marginRight": "auto", "maxWidth": "100%" }}></iframe>
            </div>
        </div>
    )

}