import style from '../../styles/RadarFestival.module.css';
import WobBanner from '../../media/Wob-banner.gif';
import wobFS from '../../media/Tess-wob-logo.png'

export default function RadarFestival_F(props) {
    return (
        <div className={style["main-container"]} >
            
        <div className={style["static-background"]} style={{backgroundImage:`url(${wobFS})`}}/>
        <div className={style["main-inner"]}>
            
            <section className={style["section-1"]}>

                <div className={style["main-title"]}>
                    WE'RE AT RADAR FESTIVAL 2024!
                </div>

                <div style={{ height: "100px" }}> </div>

                <div className={style["section-1-body"]}>
                    <div className={style["section-1-row"]}>

                        <div className={style["section-1-left"]} style={{ width: "50%" }}>

                            <h2>Check out War of Being at Radar Festival</h2>
                            If you're attending Radar Festival this year, why not check out the early access VR version of War of Being? Our friends at Radar & Scan Computers have provided
                            us with two high spec Desktop VR systems to showcase the original early access game in the Music Market over the weekend!
                            <p />
                            So if you've yet to play it in VR, come down and see what all the fuss is about!
                            <p />

                        </div>

                        <div className={style["section-1-right"]} style={{ backgroundImage: `url(${WobBanner})`, height: "200px", width: "100%" }}>

                        </div>

                    </div>

                    <p style={{height:"50px"}}/>

                    <div className={style["section-1-row"]}>
                        <div className={style["section-1-left"]} style={{ width: "50%" }}>

                            <h2>Come chat to the developers on Saturday 27th!</h2>
                            Adam and Kirsty will be kicking around the booth throughout the day to answer your questions and talk game and game design! We may even manage to steal
                            Dan away from his packed schedule to nerd out with the rest of us gamers. If you've got any burning questions, this is the place to find us!
                            <p/>
                            We hope to see you there!
                            <p/>
                        </div>
                    </div>

                    <p style={{height:"50px"}}/>

                    <div className={style["section-1-row"]}>
                        <div className={style["section-1-left"]} style={{ width: "50%" }}>

                            <h2>Where to find us</h2>
                            the VR booth will be available in the Music Market at Radar Festival.  For more information on Radar, check out the official website <a target="_blank" rel="noopener noreferrer" href="https://www.radarfestival.co.uk/">here</a>
                            <p/>
                        </div>
                    </div>

                    <p style={{height:"50px"}}/>

                </div>

            </section>
            
            </div>
        </div>
    )
}