import styles from "../styles/NewsletterSignup.module.css"
import {Link} from 'react-router-dom'

export default function NewsletterSignup(props) {


    return(
        <div className={styles["signup-button"]}>
            <Link to="/mailingsignup">Subscribe for updates</Link>
        </div>
    )
}