import style from '../../styles/RadarFaq.module.css';

export default function RadarFAQ_R(props) {

    return (
        <div>
            <p style={{paddingTop:"100px"}}/>

            {props.content.map((item, index)=>{
                return(
                    <div className={style["block-container"]}> 
                        <div className={style["question"]}>
                            {item.question}
                        </div>
                        <div className={style["answer"]}>
                            {item.answer}
                        </div>
                    </div>
                )
            })
            }

        </div>
    )
}