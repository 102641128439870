import styles from 'styles/About.module.css';
import teamBanner from 'media/TheTeam.png';
import tessLabyrinth from 'media/Tess-Lab-Logo.gif';
import profileDan from 'media/ProfilePic_Dan.jpg';
import profileAdam from 'media/ProfilePic_Adam.jpeg';
import profileKirsty from 'media/Profile_Kirsty.jpg';
import TeamMembers from 'data/TeamMemberAboutData.json';
import TeamMemberComponent from 'components/TeamMemberAbout.js';

export default function About(props) {

    return (
        <div className={styles["main-container"]}>
            <div className={styles["background-static"]}></div>
            <div className={styles["main-container-inner"]}>
                <div className={styles["brand-section"]}>
                    <div className={styles["brand-section-title"]}>
                        About the company
                    </div>
                    <div className={styles["brand-section-body"]}>
                        A new startup game studio, lead by TesseracT front-man Daniel Tompkins, DMTesseracT was created to bridge the gap between Music and Gaming industries with innovative and
                        unique game experiences. Starting with their first title War of Being, based on TesseracT's latest concept album. The DMTesseracT team are currently developing the full title after
                        a wildly successful Kickstarter campaign, following the release of the early-access demo.  With ambitious plans for the future, the team are excited and eager to carve their names in the
                        industry and build lasting relationships with their community.
                    </div>
                </div>
                <div className={styles["team-section"]}>
                    <img className={styles["team-section-banner"]} src={teamBanner} />
                    {TeamMembers.map(function (elem, index) {
                        return (<TeamMemberComponent key={index} team={elem} />)
                    })}


                </div>
            </div>
        </div>
    )
}