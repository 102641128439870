import styles from 'styles/warofbeing.module.css';
import wobMainBanner from 'media/Wob-banner.gif';
import legionA from 'media/Legion_HR1.png';
import GreyA from 'media/Grey_Vents.png';
import StrangelandGrove from 'media/Strangeland_Grove.png';

export default function WarOfBeing(props) {


    return (
        <div className={styles["main-container"]}>
            <div className={styles["background-static"]} />
            <div className={styles["main-container-inner"]}>

                <div className={styles["game-synopsis-container"]}>
                    <div className={styles["game-title"]}>

                    </div>
                    <a target='_blank' rel='noopener noreferrer' href="https://store.steampowered.com/app/2475790/War_Of_Being/">
                        <img className={styles["category-image"]} src={wobMainBanner} />
                    </a>
                    <div className={styles["synopsis-body"]}>

                        War of Being is a single-player sci-fi adventure, based on the music and concept for TesseracT's 2023 full length album of the same name. Explore ‘The Strangeland’ filled with surreal landscapes and characters, solve puzzles, find hidden objects and fight for survival as you uncover the secrets of the land’s many inhabitants.
                        War Of Being can best be described as an indie, first-person RPG with elements of sci-fi fantasy, horror & puzzle solving.
                        <p />
                        Since the release of our Early Access Steam build, we’ve been hard at work moving the game to Unreal Engine 5.3 and have begun to develop concepts, music and gameplay elements for the full game.
                        <p />
                    </div>
                </div>
            </div>

            <div className={styles["body-section-black"]}>
                <div className={styles["body-section-inner"]}>


                    <span className={`${styles["info-header"]} ${styles["text-blue"]}`}>IMMERSIVE MUSIC EXPERIENCE</span>
                    <p />
                    <span className={styles["synopsis-body"]}>
                        War of Being sets out to bridge a gap between two popular, interweaved mediums: gaming and metal music.
                        <p />
                        Explore a strange world, devised from TesseracT's latest concept album. Dive deeper into the meaning
                        of the music, meet the character's eluded to in the lyrics, and help save the story's protagonists from eternal despair.
                        <p />
                        Immerse yourself in a custom soundtrack and experience TesseracT's original War of Being album while you claw your way through the eerie, thought-provoking world.

                    </span>
                    <p />
                    <img src={legionA} style={{ width: "100%" }} />
                </div>

                <div className={styles["body-section-inner"]}>

                    <span className={`${styles["info-header"]} ${styles["text-blue"]}`}>STORY RICH WORLD</span>
                    <p />

                    <span className={styles["synopsis-body"]}>
                        A routine scientific expedition by the crew of The Dream quickly spirals into chaos, stranding the crew on the dangerous Strangeland. With the remaining crew
                        seemingly succumbing to madness, and the expedition's leaders missing, it's up to you to discover what's become of them, mount a rescue and escape the grasp of the evil that looms in the shadows.
                        <p/>
                        Immerse in rich dialogs with the crew of The Dream, befriend the inhabitants of the Strangeland and convince them to help you save Ex and El from this nightmare.
                        <br/> 
                        
                    </span>
                    <p />
                    <img src={StrangelandGrove} style={{ width: "100%" }} />

                </div>

                <div className={styles["body-section-inner"]}>

                    <span className={`${styles["info-header"]} ${styles["text-blue"]}`}>ACCESSIBLE GAMEPLAY</span>
                    <p />

                    <span className={styles["synopsis-body"]}>
                        Build relationships with the locals & solve thought-provoking puzzles as you press on through the Strangeland. War of Being aims to appease both gamers and
                        avid music fans alike, regardless of their gaming skill. 
                    </span>
                    <p />
                    <img src={GreyA} style={{ width: "100%" }} />

                </div>

                <div className={styles["body-section-inner"]}>
                <div>
                <p />
                <img className={styles["category-image"]} src={""} />
                <p />
                <span className={`${styles["info-header"]} ${styles["text-blue"]}`}>LATEST MEDIA</span>
                <p />
                <div className={styles["video-container"]}>

                    <div style={{ position: "relative", width: "90%", paddingBottom: "56.25%" }}>
                        <iframe src="https://drive.google.com/file/d/1XXfcb_LHtQ-gsU4mvZ5igkH4ZVOS16gJ/preview" style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} width="100%" allow="fullscreen"></iframe>
                    </div>
                </div>
                <p />
            </div>
            </div>

            </div>


      

        </div>
    )
}