import styles from 'styles/Register.module.css';
import {useState} from 'react';

export default function (props) {

const [emailError, setEmailError] = useState(null);
const [usernameError, setUsernameError] = useState(null);
const [passwordError, setPasswordError] = useState(null);
const [genericError, setGenericError] = useState(null);


    async function submitRegistration() {

        var username = document.getElementById("username").value;
        var password = document.getElementById("password").value;
        var invitation = document.getElementById("invitationCode").value;

        var response = await fetch(process.env.REACT_APP_API_ROOT, {
            method:"POST",
            headers:{
                "content-type":"application/json"
            },
            body:{
                username:username,
                password:password,
                token:invitation
            }
        });

        if(response.status === 400) {
            setGenericError("Something went wrong.");
            return;
        }

        if(response.status === 409) {
            setUsernameError("Username already exists.");
        }

        if(response.status === 200) {
            var data = await response.json();
            // store 
            return;
        }

    }   


    function preSubmitRegistration() {
        setEmailError(null);
        setUsernameError(null);
        setPasswordError(null);
        setGenericError(null);

        const validUsername = assertUsername();
        const validPassword = assertPassword();
        const validEmail = assertEmail();
        if(validUsername && validPassword && validEmail) {
            submitRegistration();
        }
    }

    function assertEmail() {
        var email = document.getElementById("email").value;
        if(!new RegExp(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/).test(email)) {
            setEmailError("Invalid email");
            return false;
        }
        return true;
    }

    function assertUsername() {
        var username = document.getElementById("username").value;
        if(!new RegExp(/^[a-zA-Z][a-zA-Z0-9]+/).test(username)) {
            setUsernameError("Invalid username.");
            return false;
        }
        return true;
    }


    function assertPassword() {
        var passwordMinLength = 8;
        var passwordMaxLength = 50;
        var password = document.getElementById("password").value;
        
        if (password.length < passwordMinLength || password.length > passwordMaxLength) {
            setPasswordError("Password must be between 8 and 50 characters.");
            return false;
        } 
        
        return true;
    }


    return (
        <div className={styles["main-container"]}>
            <div className={styles["registration-container-outer"]}>
                
                <div className={styles["registration-container"]}>
                    <span>Register for the war of being beta feedback.</span>
                    <p/>
                    
                    {emailError ? <div>{emailError}</div> : <></>}
                    <div>E-mail</div>
                    <div><input id="email" type="email"></input></div>  
                    
                    {usernameError ? <div>{usernameError}</div> : <></>}
                    <div>Username</div>
                    <div><input id="username" type="text"></input></div>

                    {passwordError ? <div>{passwordError}</div> : <></> }                    
                    <div>Password</div> 
                    <div> <input id="password" type="password"></input></div>
                    
                    <div>Invite Code</div>
                    <div><input id="invitationCode"></input> </div>

                    <div className={styles["registration-submit"]} onClick={()=>{preSubmitRegistration()}}>Submit</div>
                    {genericError ? <div>{genericError}</div> : <></>}                    
                </div>
            </div>
        </div>
    )
}