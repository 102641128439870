import styles from 'styles/About.module.css';

export default function TeamMemberAbout(props) {



    return (
        <div className={styles["team-info-container"]}>
            <div className={styles["team-pic-container"]}>
                <img className={styles["team-profile-pic"]} onError={e => {e.currentTarget.src = "/teamprofile/Profile_Blank.png"}} src={props.team.imageUrl} />
            </div>
            <div className={styles["team-info-title"]}>
                {props.team.name} - {props.team.role}
                <br /> {props.team.location}
                <p />
                <div className={styles["team-info-body"]}>
                "{props.team.experience}"
                    <p />
                    "{props.team.personalStatement}"
                    <p />
                </div>
            </div>
        </div>
    )

}