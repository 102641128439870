import RadarFAQ_R from './RadarFAQ_R.js';

export default function RadarFAQ_F() {

    const content = [
        {
            "question":"Where and when can we buy the game?",
            "answer":"The game is released in early-access form on Steam right now! The full version is aimed to be ready for Q4 2025."
        },
        {
            "question":"What's the story about?",
            "answer":"The story is based on TesseracT's concept album War of Being. The back story was originally created by Amos and advanced by Dan. The core concept revolves around two main protagonists; Ex and El, who are ripped apart by tragedy in their lives. Thrust into an unknown world, their personalities manifest into symbolic characters that populate the strangeland they find themselves in. They must work to reunite their divided souls or risk being lost in the grip of Fear forever."
        },
        
        {
            "question":"Is the entire album going to be available in game?",
            "answer":"That's the plan! We want the story and music to be completely intertwined. We're putting a lot of energy into making sure the music is integrated in a fun and interesting way."
        },
        {
            "question":"Are there plans for the game to be available without VR?",
            "answer":"Currently, yes. The early access has full desktop support. The full version has way more mechanics that lean towards VR, so this might be forced to change, however, we are doing our best to keep it as accessible as possible."
        },
        {
            "question":"What other platforms is the game on?",
            "answer":"As it stands, the game is only available on Steam. We are looking into porting it to PS5 and any other hardware that may support the tech at a decent performance, but we don't have confirmation of this yet."
        },
        {
            "question":"What engine is it built in?",
            "answer":"The early-access build was created in Unreal Engine 5.1, ongoing development is currently using Unreal Engine 5.3."
        },
        {
            "question":"What VR Headsets are supported?",
            "answer":"Any kit that is compatible with SteamVR will work."
        },
        {
            "question":"Does it support motion tracking?",
            "answer":"No, VR is limited to conventional VR Controllers. Due to time constraints, we have no plans to support advanced VR input devices."
        },
        {
            "question":"Can I use an Xbox Controller?",
            "answer":"VR Mode only supports conventional VR Controllers. Desktop mode has full gamepad support for any XInput device. Experimental support was included for Nintendo Switch Controllers, but will be withdrawn in a future patch."
        },
        {
            "question":"Is Dan working on the game himself?",
            "answer":"Yes, Dan is directly involved in the game. Dan is creative director as well as getting his hands dirty as an environment artist!"
        },
        {
            "question":"Is the rest of TesseracT involved?",
            "answer":"The short answer is; no, not directly. The guys keep tabs on where the game is going, but don't get directly involved."
        },
        {
            "question":"Who else is working on the game?",
            "answer":"Dan, Adam and Kirsty are the main individuals that are working on the project.  The team is currently expanding through volunteers and university students looking to get experience with a commercial project."
        },
        {
            "question":"How can I contact the team?",
            "answer":"If you're interested in reaching out, you can contact us at inquiries@dmtesseract.com."
        },
        {
            "question":"Is there a social hub for the team/game?",
            "answer":"The best public space for followers of the game is the official Tesseract discord server."
        },
        {
            "question":"What's progress on the development?",
            "answer":"We've pretty much scrapped all of the content you see in the early-access in favour of completely refined mechanics and visuals, so what you see in the current version will be replaced with an overhaul update as soon as it's ready! You can get a glimpse of the current development on the official game page at www.dmtesseract.com/warofbeing"
        },
        {
            "question":"Is this a horror game?",
            "answer":"It definitely leans into the horror element. Dan is as big fan of the genre, and the story concept lends itself perfectly to that atmosphere."
        },
        {
            "question":"Will the final release cost more?",
            "answer":"We do plan on raising the purchase price once the update is shipped. This will completely replace the existing version, but it will be the same product, so if you purchased the early-access at its current price, you'll get the final version at no extra cost."
        }

    ]

    return (
       <RadarFAQ_R 
            content={content}
       />
    )
}