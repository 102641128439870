import styles from 'styles/Home.module.css';
import { Link } from 'react-router-dom';
import wobVideo from "media/wob_trailer_env.mp4";
import wobBanner from 'media/Wob-banner.gif';
import availableSteam from 'media/available_on_steam.png';
import MailingListButton from '../components/NewsletterSignupButton.js';
import MListImg from 'media/Mailing_List.gif';
import NewsImg from 'media/Latest_News.gif';
import DiscordImg from 'media/DISCORD.gif';

export default function Home() {

    return (

        <div className={styles["home-container"]}>
            <div className={styles["section-one-container"]}>
                <video className={styles["video-settings"]} autoPlay={true} muted={true} loop={true}>
                    <source src={wobVideo} type="video/mp4" />
                </video>
            </div>
            <div className={styles["news-header-container"]}>
                <div className={styles["news-header"]}> <a href="https://store.dmtesseract.com">Limited edition War of Being gear available now!  </a> </div>
            </div>

            <div className={styles["section-one-overlay-container"]}>

                <div className={styles["section-one-overlay-inner"]}>
                    <div className={styles["section-one-indevelopment"]}>
                        <div className={styles["section-one-title"]}>In Development</div>
                        <Link className={styles["section-one-banner-container"]} to="/warofbeing">
                            <img className={styles["section-one-banner"]} src={wobBanner} />
                        </Link>
                        <div className={styles["steam-link-container"]}>
                            <div>
                                <a target='_blank' rel='noopener noreferrer' href="https://store.steampowered.com/app/2475790/War_Of_Being/">
                                    <img className={styles["steam-link-image"]} src={availableSteam} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles["blog-outer"]}>
                        <div className={styles["blog-container"]}>
                            <div className={styles["blog-container-inner"]}>
                                <a href="https://www.kickstarter.com/projects/dmtesseract/war-of-being/posts/4068150" target="_blank"><img className={styles["nav-image"]} src={NewsImg} /></a> <br />
                                <a href="https://discord.com/invite/wnC7zuU3p9" target="_blank"><img className={styles["nav-image"]} src={DiscordImg} /></a> <br />
                                <Link to="/mailingsignup"><img className={styles["nav-image"]} src={MListImg} /></Link> <br />
                            </div>
                        </div>
                    </div>

                </div>





                <div className={styles["section-two-container"]}>
                    <div className={styles["section-two-inner"]}>
                        <div className={styles["section-two-title"]}>
                            Welcome to DMTesseracT
                        </div>
                        <div className={styles["section-two-body"]}>
                            We're a small Indie game studio with big ambitions and ideas. We're currently knee deep in our first full-length title "War of Being", based on the concept album by the progressive metal band <a className={styles["tesseract-link"]} href="https://www.tesseractband.co.uk/" target="_blank">TesseracT</a>.
                            War of Being takes players through an invigorating, story-driven world where they experience the story behind the concept while experiencing the album. War of Being seeks to branch two mediums and bring
                            both music enthusiasts and gamers together for a unique experience.
                            <p />
                            <MailingListButton />
                        </div>
                    </div>
                </div>
            </div>



        </div >);
}